<template>
    <div class="slide-mobile-menu-content" @touchstart="startDrag" @touchend="endDrag" @click.stop>
        <div class="close-button" data-cy="closeMobileMenu" @click.stop="() => emit('closeMobileMenu')">
            <span>×</span>
        </div>
        <div class="scroll-wrapper">
            <div class="scroll" :class="`level-${currentLevel}`">
                <ul>
                    <template v-for="(navItem, index) in navItems" :key="navItem._uid">
                        <li
                            v-if="navItem.component !== 'MenuIcon' && navItem.component !== 'Separator'"
                            :class="navItemClasses(navItem)"
                        >
                            <LinkItem v-if="navItem.component === 'Link'" :nav-item="navItem" />
                            <LinkHtmlContent v-else-if="navItem.component === 'LinkHtmlContent'" :nav-item="navItem" />
                            <HtmlContent v-else-if="navItem.component === 'NavHtmlItem'" :nav-item="navItem" />
                            <span
                                v-else-if="navItem.component === 'Dropdown'"
                                :data-cy="`mobileMenuDropdown-${index}`"
                                :class="{ active: activeItems[navItem._uid] }"
                                @click.prevent.stop="changeLevel(2, navItem)"
                            >
                                {{ navItem.Title }}
                                <NuxtIcon name="ri:arrow-right-s-line" />
                            </span>
                        </li>
                        <hr
                            v-if="navItem.component === 'Separator'"
                            :style="`border-bottom: 1px solid ${navItem.Color.color}`"
                        />
                    </template>
                </ul>
                <template v-for="key in Object.keys(menuLevelsItems)" :key="`menu-level-${key}`">
                    <MenuLevel
                        v-if="key > 1"
                        :current-level="currentLevel"
                        :nav-item="menuLevelsItems[key]"
                        :level="parseInt(key)"
                        @change-menu-level="changeLevel"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import LinkHtmlContent from '~ui/components/Header/Components/NavItem/LinkHtmlContent.vue';
import MenuLevel from '~ui/components/Header/Components/Mobile/MenuLevel.vue';
import LinkItem from '~ui/components/Header/Components/NavItem/LinkItem.vue';
import HtmlContent from '~ui/components/Header/Components/NavItem/HtmlContent.vue';
import { isCurrentPageLink } from '~ui/utils/navigations';

const { navItems, mobileMenuIsActive } = defineProps<{
    navItems: [];
    mobileMenuIsActive: boolean;
    activeItems: {};
}>();

const route = useRoute();
// swipe event initial values
const startX = ref(0);
const startTime = ref(0);
const minSwipeDistance = ref(50);
const minSwipeSpeed = ref(0.5);
const currentLevel = ref(0);
const menuLevelsItems = ref({});
const emit = defineEmits<{
    (e: 'closeMobileMenu'): void;
}>();

const startDrag = (e: any) => {
    startX.value = e.changedTouches[0].clientX;
    startTime.value = Date.now();
};
const endDrag = (e: any) => {
    const endX = e.changedTouches[0].clientX;
    const endTime = Date.now();
    const deltaX = endX - startX.value;
    const deltaTime = endTime - startTime.value;
    const speed = Math.abs(deltaX) / deltaTime;

    if (deltaX > 0 && Math.abs(deltaX) > minSwipeDistance.value && speed > minSwipeSpeed.value) {
        // Swipe right detected with sufficient distance and speed and close mobile menu
        closeMobileMenu();
    }
};

const navItemClasses = (navItem: any) => ({
    'drop-down-button': navItem.Options,
    'current-page-nav-item': isCurrentPageLink(route, navItem),
});

const closeMobileMenu = () => {
    currentLevel.value = 0;
    emit('closeMobileMenu');
};

const changeLevel = (level: number, activeNavItem: any) => {
    currentLevel.value = level;
    menuLevelsItems.value[level] = activeNavItem;
};

watch(
    () => mobileMenuIsActive,
    (newVal: boolean) => {
        if (!newVal) {
            currentLevel.value = 0;
        }
    }
);

(function calculateMenuLevels(items, level) {
    menuLevelsItems.value[level] = [];
    items.forEach((item: any) => {
        if (item.component === 'Dropdown') {
            calculateMenuLevels(item.Options, level + 1);
        }
    });
})(navItems, 1);
</script>

<style scoped lang="postcss">
.slide-mobile-menu-content {
    @apply h-full;

    li {
        @apply border-b border-[#adbfd1] text-[0.875rem] text-[#243845];
        &:empty {
            @apply hidden;
        }

        a,
        span {
            @apply flex items-center p-[1.032rem] pl-0 pr-0 relative;

            > .iconify {
                @apply self-center transition-transform ease-in duration-300 absolute right-4;
            }
        }

        div {
            @apply flex items-center justify-center w-4 mr-2;

            .iconify {
                @apply text-primary h-4;
            }
        }

        .nav-mobile-dropdown {
            @apply hidden;

            &.active {
                @apply block;
            }

            li {
                @apply pl-9;
            }
        }

        &.drop-down-button {
            @apply border-b-0;

            > a {
                @apply w-full flex items-center border-b border-[#adbfd1] pb-4;
            }
        }
    }
}
</style>
