<template>
    <div class="mobile-menu new-header" :class="componentClasses" @click.stop>
        <SlideMobileMenu
            v-if="
                blok.mobileMenuStyle === EMobileMenuStyles.SLIDE_LEFT ||
                blok.mobileMenuStyle === EMobileMenuStyles.SLIDE_RIGHT
            "
            :nav-items="blok.MobileNavigation"
            :mobile-menu-is-active="mobileMenuIsActive"
            :mobile-menu-style="blok.mobileMenuStyle"
            :active-items="activeItems"
            @close-mobile-menu="() => emit('closeMobileMenu')"
        />
        <DropdownMobileMenu
            v-else-if="blok.mobileMenuStyle === EMobileMenuStyles.DROPDOWN"
            :mobile-menu-is-active="mobileMenuIsActive"
            :active-items="activeItems"
            :nav-items="blok.MobileNavigation"
            @toggle-menu-item="
                (e, navItem, accessibility, innerDropDown, main) =>
                    emit('toggleMenuItem', e, navItem, accessibility, innerDropDown, main)
            "
            @on-click-nav-item="(e, navItem) => emit('onClickNavItem', e, navItem)"
        />
    </div>
</template>

<script setup lang="ts">
import { EMobileMenuStyles } from '~ui/types/enums/EMobileMenuStyles';
import SlideMobileMenu from '~ui/components/Header/Components/Mobile/SlideMobileMenu.vue';
import DropdownMobileMenu from '~ui/components/Header/Components/Mobile/DropdownMobileMenu.vue';

const { blok, mobileMenuIsActive } = defineProps<{
    blok: any;
    mobileMenuIsActive: boolean;
    activeItems: {};
}>();

const emit = defineEmits<{
    (e: 'closeMobileMenu'): void;
    (
        e: 'toggleMenuItem',
        event: any,
        navItem: any,
        accessibility: boolean,
        innerDropDown: boolean,
        main: boolean
    ): void;
    (e: 'onClickNavItem', event: any, navItem: any): void;
}>();

const componentClasses = computed(() => ({
    active: mobileMenuIsActive,
    'slide-left': blok.mobileMenuStyle === EMobileMenuStyles.SLIDE_LEFT,
    'slide-right': blok.mobileMenuStyle === EMobileMenuStyles.SLIDE_RIGHT,
    'slide-mobile-menu': [EMobileMenuStyles.SLIDE_RIGHT, EMobileMenuStyles.SLIDE_LEFT].includes(blok.mobileMenuStyle),
    'dropdown-mobile-menu': blok.mobileMenuStyle === EMobileMenuStyles.DROPDOWN,
}));
</script>

<style scoped lang="postcss">
.mobile-menu.dropdown-mobile-menu {
    @apply hidden;
    &.active {
        @apply max-md:block;
    }
}

.mobile-menu.slide-mobile-menu {
    @apply md:hidden fixed top-0 right-0 transform translate-x-full transition-transform ease-in-out duration-[400ms] bottom-0 w-[75vw] bg-white z-50 h-[calc(100vh)] pl-4 pt-12 overflow-y-scroll;
    box-shadow: -3px -4px 20px 0 rgba(0, 0, 0, 0.1);
    &.slide-left {
        @apply -translate-x-full;
    }

    &.active {
        @apply transform translate-x-0;
    }

    &.new-header {
        @apply w-[75vw] overflow-x-hidden pt-6 pl-6;

        :deep(.slide-mobile-menu-content) {
            li {
                @apply border-none mb-5;

                div .iconify {
                    @apply text-[#293f4d];
                }

                a,
                span {
                    @apply py-0 cursor-pointer;

                    &:hover {
                        @apply underline;
                    }
                }

                .fa-angle-right {
                    @apply text-[20px];
                }

                &:first-of-type {
                    a,
                    span {
                        @apply pt-4;
                    }
                }

                &.drop-down-button {
                    @apply border-b-0;

                    > a {
                        @apply border-none;
                    }

                    .iconify {
                        @apply text-base text-[20px];
                    }
                }
            }

            .scroll-wrapper {
                @apply flex overflow-x-hidden;

                .scroll {
                    @apply inline-grid grid-cols-[repeat(3,75vw)] transition-[transform] duration-[0.6s];

                    > ul > hr {
                        @apply my-5;
                    }

                    &.level-2 {
                        @apply translate-x-[-75vw];
                    }

                    &.level-3 {
                        @apply translate-x-[-150vw];
                    }

                    .level-wrapper,
                    > ul {
                        @apply pr-[30px] pt-3;
                    }

                    .level-wrapper {
                        hr {
                            &.top {
                                @apply mt-6;
                            }

                            &.bottom {
                                @apply mb-3;
                            }
                        }
                    }

                    .back-button {
                        @apply flex gap-2 cursor-pointer;
                    }

                    .title {
                        @apply min-h-[48px] flex items-center text-sm font-semibold;
                    }
                }
            }
        }
    }

    .close-button {
        @apply absolute right-0 top-[10px] z-[1];

        span {
            @apply cursor-pointer flex items-center justify-center w-16 text-[2rem] font-light text-[#243845];
        }
    }
}

.mobile-menu.dropdown-mobile-menu {
    @apply z-50 absolute inset-x-0;
    background: inherit;
}
</style>
