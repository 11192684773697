<template>
    <nuxt-link class="link-text-nav-item" :to="getInternalLink(navItem?.Link)" :style="itemStyles" external>
        <SimpleRichtext :blok="{ text: navItem.Text }" />
    </nuxt-link>
</template>

<script setup lang="ts">
import { getInternalLink } from '../../../../../../apps/_base/utils/internalLinking';

const { navItem } = defineProps<{ navItem: any }>();

const itemStyles = computed(() => ({
    'background-color': navItem.background?.color || null,
    color: navItem.color?.color || null,
}));
</script>

<style scoped lang="postcss"></style>
