<template>
    <div class="mobile-dropdown-menu-wrapper" :class="{ active: mobileMenuIsActive }" @click.stop>
        <div class="dropdown-menu-wrapper">
            <ul>
                <template v-for="navItem in navItems" :key="navItem._uid">
                    <li
                        v-if="navItem.component !== 'MenuIcon' && navItem.component !== 'Separator'"
                        :class="{
                            'drop-down-button': navItem.Options,
                            'rich-text-menu-item':
                                navItem.component === 'LinkHtmlContent' || navItem.component === 'NavHtmlItem',
                            'current-page-nav-item': isCurrentPageLink(route, navItem),
                        }"
                        @click.stop="(e: any) => onClickNavItem(e, navItem)"
                    >
                        <LinkItem v-if="navItem.component === 'Link'" :nav-item="navItem" />
                        <LinkHtmlContent v-else-if="navItem.component === 'LinkHtmlContent'" :nav-item="navItem" />
                        <HtmlContent v-else-if="navItem.component === 'NavHtmlItem'" :nav-item="navItem" />
                        <Dropdown
                            v-else-if="navItem.component === 'Dropdown'"
                            :nav-item="navItem"
                            :active-items="activeItems"
                            @toggle-dropdown="toggleDropdown"
                            @on-click-nav-item="onClickNavItem"
                        />
                    </li>
                    <hr
                        v-if="navItem.component === 'Separator'"
                        :style="`border-bottom: 1px solid ${navItem.Color.color}`"
                    />
                </template>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import LinkHtmlContent from '~ui/components/Header/Components/NavItem/LinkHtmlContent.vue';
import HtmlContent from '~ui/components/Header/Components/NavItem/HtmlContent.vue';
import LinkItem from '~ui/components/Header/Components/NavItem/LinkItem.vue';
import Dropdown from '~ui/components/Header/Components/NavItem/Dropdown.vue';
import { isCurrentPageLink } from '~ui/utils/navigations';

defineProps<{
    activeItems: {};
    navItems: [];
    mobileMenuIsActive: boolean;
}>();

const route = useRoute();
const emit = defineEmits<{
    (e: 'closeMobileMenu'): void;
    (
        e: 'toggleMenuItem',
        event: any,
        navItem: any,
        accessibility: boolean,
        innerDropDown: boolean,
        main: boolean
    ): void;
    (e: 'onClickNavItem', event: any, navItem: any): void;
}>();

const onClickNavItem = (e: any, navItem: any) => {
    emit('onClickNavItem', e, navItem);
};

const toggleDropdown = (e: any, navItem: any, accessibility = false, innerDropDown = false, main = false) => {
    emit('toggleMenuItem', e, navItem, accessibility, innerDropDown, main);
};
</script>

<style scoped lang="postcss">
.mobile-dropdown-menu-wrapper {
    li {
        a {
            @apply px-0.5 py-8 text-center block;
        }

        &.rich-text-menu-item {
            a {
                @apply block pt-2.5 pb-[5px] px-[15px] h-auto;
                :deep(p) {
                    @apply m-0 text-inherit;
                }
            }
        }
    }

    .level-wrapper {
        li {
            a {
                @apply px-[15px] py-[7px];
            }
        }
    }
}
</style>
