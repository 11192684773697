<template>
    <div class="navigation-button md:hidden flex" :class="{ 'is-open': mobileMenuIsActive }" data-cy="openMobileMenu">
        <button
            v-if="displayDefaultBtn"
            class="default-mobile-menu-toggler"
            :class="{ 'is-open': mobileMenuIsActive }"
            @click="toggleMobileMenu"
        >
            <span>Menu</span>
        </button>
        <div v-else-if="mobileMenuIsActive" class="close-mobile-button" @click.stop="() => emit('closeMobileMenu')">
            <NuxtIcon name="ri:close-fill" />
        </div>
        <div v-else class="open-mobile-button" @click.stop="() => emit('openMobileMenu')">
            <NuxtIcon name="ri:menu-fill" />
        </div>
    </div>
</template>

<script setup lang="ts">
const { mobileMenuIsActive, menuIconComponent } = defineProps<{
    mobileMenuIsActive: boolean;
    menuIconComponent?: any;
}>();

const emit = defineEmits<{
    (e: 'openMobileMenu'): void;
    (e: 'closeMobileMenu'): void;
}>();

const displayDefaultBtn = computed(() => !menuIconComponent?.Icon?.icon);
const toggleMobileMenu = () => {
    if (mobileMenuIsActive) {
        emit('closeMobileMenu');
        return;
    }
    emit('openMobileMenu');
};
</script>

<style scoped lang="postcss">
.navigation-button {
    @apply cursor-pointer absolute justify-center items-center right-[0rem] top-[0rem] bottom-[-0.5rem] w-16 text-[1.5rem] text-primary;

    .iconify {
        @apply h-8 p-1;
    }
}

.default-mobile-menu-toggler {
    @apply block relative w-10 h-10 ml-2.5 bg-transparent z-50 border-none;
    text-indent: -9999px;

    &:before,
    &:after,
    span {
        @apply bg-white rounded absolute left-1/4 right-1/4 h-1 -mt-0.5 duration-200 transition-all ease-linear;
    }

    &:before,
    &:after {
        content: '';
    }

    &:before {
        @apply top-[30%];
    }

    &:after {
        @apply top-[70%];
    }

    span {
        top: 50%;

        .is-open & {
            @apply opacity-0;
        }
    }

    &.is-open {
        &:before,
        &:after {
            @apply bg-primary top-1/2 left-[15%] right-[15%];
        }

        &:before {
            @apply rotate-45;
        }

        &:after {
            @apply -rotate-45;
        }

        span {
            @apply opacity-0;
        }
    }
}
</style>
