<template>
    <div class="level-wrapper" :class="`sublevel-${level}`">
        <p class="back-button" @click.stop="changeMenuLevel(currentLevel - 1)">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
                <path
                    d="M16.67 9.17v1.66h-10l4.58 4.59-1.18 1.18-6.6-6.6 6.6-6.6 1.18 1.18-4.58 4.59h10Z"
                    fill="#293F4D"
                />
            </svg>
            Back
        </p>
        <hr class="top" />
        <nuxt-link
            v-if="navItem.Link?.url || navItem.Link?.cached_url"
            :to="getInternalLink(navItem.Link)"
            :target="navItem.Link?.target"
            class="title"
            external
        >
            {{ navItem.Title }}
        </nuxt-link>
        <p v-else class="title">{{ navItem.Title }}</p>
        <hr class="bottom" />
        <ul class="nav-mobile-dropdown">
            <template v-if="navItem">
                <template v-for="(dropdownItem, dIndex) in navItem.Options" :key="dropdownItem._uid">
                    <li
                        class="nav-mobile-dropdown-item"
                        :class="{ 'current-page-nav-item': isCurrentPageLink(route, dropdownItem.Link) }"
                        :data-cy="`mobileMenuDropdownLevel${currentLevel}-${dIndex}`"
                    >
                        <LinkItem v-if="dropdownItem.component === 'Link'" :nav-item="dropdownItem" />
                        <span v-else @click.stop="changeMenuLevel(currentLevel + 1, dropdownItem)">
                            {{ dropdownItem.Title }}
                            <i class="fa-solid fa-angle-right"></i>
                        </span>
                    </li>
                </template>
            </template>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { isCurrentPageLink } from '~ui/utils/navigations';
import LinkItem from '~ui/components/Header/Components/NavItem/LinkItem.vue';
import { getInternalLink } from '../../../../../../apps/_base/utils/internalLinking';

defineProps<{ navItem: any; currentLevel: number; level: number }>();
const route = useRoute();
const emit = defineEmits<{
    (e: 'changeMenuLevel', level: number): void;
}>();

const changeMenuLevel = (level: number, navItem: any) => {
    emit('changeMenuLevel', level, navItem);
};
</script>

<style scoped lang="postcss"></style>
